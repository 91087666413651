import {
  Banner,
  Button,
  LogoUploader,
  Select,
  sidePanel as SidePanel,
  TextInput,
  Toggle,
  setClass,
  urlValidator,
  useInvalidations,
  useRefs,
  useShadowOnScroll,
} from '@kandji-inc/bumblebee';
import RTFFileSelect from 'components/common/upload/rtf-file-select';
import RTFFileViewer from 'components/common/upload/rtf-file-viewer';
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import DefaultDesktopBg from '../../assets/macos-monterey.png';
import DefaultDesktopBgSonoma from '../../assets/sonoma-horizon.png';
import {
  mapInvalidIndexToFieldWithRefs,
  requiredFileValidator,
  requiredTextValidator,
} from '../../input-validations';
import KandjiLoginService from '../../service/kandji-login-service';
import DefaultKandji from '../common/assets/kandji-bee-logo-transparent.png';

const Drawer = (props) => {
  const { isVisible, setIsVisible, setting, authSetting, update, onClose } =
    props;
  const {
    isDesktopBg,
    isLogo,
    lockMessageOption,
    policyBannerOption,
    isCustomUsername,
    isIncludePasswordUrl,
  } = setting;
  const { isShowHeaderShadow, setBody } = useShadowOnScroll();
  const refs = useRefs(6);
  const { invalidations, onInvalidate } = useInvalidations({ inputs: 6 });
  const invalidationsMap = mapInvalidIndexToFieldWithRefs(
    invalidations,
    [
      'brandingLogo',
      'brandingDesktopImage',
      'bannerLock',
      'bannerPolicy',
      'customUsername',
      'passwordResetUrl',
    ],
    refs,
  );
  const [toggleValidation, setToggleValidation] = useState();

  useEffect(() => {
    update('invalidationsMap', (p) => ({ ...p, ...invalidationsMap }));
  }, [...invalidations]);

  const lockOptions = [
    { label: i18n.t("Don't display a lock message"), value: 'none' },
    { label: i18n.t('Display a custom lock message'), value: 'custom' },
    { label: i18n.t('Inherit system settings'), value: 'inherit' },
  ];

  const policyOptions = [
    { label: i18n.t("Don't display a policy banner"), value: 'none' },
    { label: i18n.t('Display a plain-text policy banner'), value: 'plain' },
    { label: i18n.t('Display an RTF policy banner'), value: 'rtf' },
    { label: i18n.t('Inherit system settings'), value: 'inherit' },
  ];

  const onCancel = () => {
    setIsVisible(false);
    onClose();
  };

  const LDFFpassportAllowOpenWifiNetworks = featureFlags.getFlag(
    'dc-06142024_passport-allow-open-wifi-networks',
  );
  return (
    <SidePanel isVisible={isVisible}>
      <div className="b-side-panel-layout">
        <div
          className={setClass(
            'b-side-panel-layout__header',
            isShowHeaderShadow && '--with-shadow',
          )}
        >
          <h2 className="b-h2">{i18n.t('Customize login window')}</h2>
        </div>
        <div
          className="b-side-panel-layout__body --k-ss2-style-body hubspot-buffer-bottom"
          ref={setBody}
        >
          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3">{i18n.t('Branding')}</h3>
            {authSetting.idp &&
              authSetting.mode ===
                KandjiLoginService.authenticationMode.WEB_LOGIN && (
                <Banner kind="block" theme="info" className="b-mb2">
                  <span>
                    {i18n.t(
                      'Logo will only be displayed at the Local Login window.',
                    )}
                  </span>
                </Banner>
              )}
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">{i18n.t('Display logo')}</p>
                  <p className="b-txt-light">
                    {i18n.t(
                      "Include your organization's logo on the login window.",
                    )}
                  </p>
                </div>
                <div>
                  <Toggle
                    checked={isLogo}
                    onToggle={() => update('isLogo', !isLogo)}
                  />
                </div>
              </div>
              {setting.isLogo && (
                <div ref={refs[0]} className="b-mt2">
                  <p className="b-txt">{i18n.t('Logo')}</p>
                  <p className="b-txt-light">
                    {i18n.t(
                      'A 128x128 pixel PNG file with a transparent background is recommended.',
                    )}
                  </p>
                  <LogoUploader
                    className="b-mt1 k-klogin-logo-preview"
                    smallPreview
                    icon={setting.logoUrl || setting.logo || DefaultKandji}
                    onRemove={() => {
                      update('logoUrl', '');
                      update('logoS3Key', '');
                      update('logo', null);
                    }}
                    onImage={(img) => {
                      update('logoUrl', '');
                      update('logoS3Key', '');
                      update('logo', img);
                    }}
                    canRemove={setting.logoUrl || setting.logo}
                    validators={[
                      (v) => null,
                      (file) => {
                        if (!file) {
                          return null;
                        }

                        const allowedFileTypes = ['jpg', 'jpeg', 'png'];

                        if (
                          !allowedFileTypes.some((ftype) =>
                            file?.type?.includes(ftype),
                          )
                        ) {
                          return i18n.t(
                            'Image needs to be in PNG or JPEG format',
                          );
                        }

                        return null;
                      },
                    ]}
                    runValidatorsOn={[toggleValidation]}
                    renderTip={({ icon }) => (
                      <>
                        {icon}
                        <p className="b-txt">
                          {i18n.t('Drop image here or')}{' '}
                          <a
                            href=""
                            onClick={(e) => e.preventDefault()}
                            className="b-alink"
                          >
                            {i18n.t('upload')}
                          </a>
                        </p>
                        <p className="b-txt k-ssw-style-drawer-bg-tip">
                          JPEG, PNG
                        </p>
                      </>
                    )}
                  />
                </div>
              )}
            </div>
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">{i18n.t('Customize Desktop picture')}</p>
                  <p className="b-txt-light">
                    {i18n.t(
                      'Upload a custom image to display on the login window.',
                    )}
                  </p>
                </div>
                <div>
                  <Toggle
                    checked={isDesktopBg}
                    onToggle={() => {
                      update('isDesktopBg', !isDesktopBg);
                      if (
                        !isDesktopBg &&
                        !setting.desktopBgUrl &&
                        !setting.desktopBg
                      ) {
                        onInvalidate(1)(true);
                      } else {
                        onInvalidate(1)(false);
                      }
                    }}
                  />
                </div>
              </div>
              {setting.isDesktopBg && (
                <div ref={refs[1]} className="b-mt2">
                  <p className="b-txt">{i18n.t('Desktop picture')}</p>
                  <p className="b-txt-light">
                    {i18n.t('A 3840x2160 pixel image file is recommended.')}
                  </p>
                  <LogoUploader
                    className="b-mt1 li-passport__login-bg-uploader"
                    // accept=".heic,image/*"
                    largePreview
                    icon={
                      setting.desktopBgUrl ||
                      setting.desktopBg ||
                      DefaultDesktopBgSonoma ||
                      DefaultDesktopBg
                    }
                    onRemove={() => {
                      update('desktopBgUrl', '');
                      update('desktopBgS3Key', '');
                      update('desktopBg', null);
                      onInvalidate(1)(true);
                    }}
                    onImage={(img) => {
                      update('desktopBgUrl', '');
                      update('desktopBgS3Key', '');
                      update('desktopBg', img);
                      onInvalidate(1)(false);
                    }}
                    canRemove={setting.desktopBgUrl || setting.desktopBg}
                    validators={[
                      (file) =>
                        requiredFileValidator(
                          i18n.t(
                            'Upload a custom image or turn the toggle off to use the default image. ',
                          ),
                        )(setting.desktopBgUrl || file),
                      (file, image) => {
                        if (image.height < 500 || image.width < 1024) {
                          return i18n.t(
                            'Image should be at least 1024 x 500 pixels',
                          );
                        } else if (image.height > 6016 || image.width > 6016) {
                          return i18n.t(
                            'Image can be a maximum of 6016 x 6016 pixels',
                          );
                        }
                      },
                      (file) => {
                        if (!file) {
                          return null;
                        }

                        const allowedFileTypes = ['jpg', 'jpeg', 'png'];

                        if (
                          !allowedFileTypes.some((ftype) =>
                            file?.type?.includes(ftype),
                          )
                        ) {
                          return i18n.t(
                            'Image needs to be in PNG or JPEG format',
                          );
                        }

                        return null;
                      },
                    ]}
                    runValidatorsOn={[toggleValidation]}
                    renderTip={({ icon }) => (
                      <>
                        {icon}
                        <p className="b-txt">
                          {i18n.t('Drop image here or')}{' '}
                          <a
                            href=""
                            onClick={(e) => e.preventDefault()}
                            className="b-alink"
                          >
                            {i18n.t('upload')}
                          </a>
                        </p>
                        <p className="b-txt k-ssw-style-drawer-bg-tip">
                          {/* JPEG, PNG, HEIC */}
                          JPEG, PNG
                        </p>
                      </>
                    )}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3">{i18n.t('Menu bar')}</h3>
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">{i18n.t('Network manager')}</p>
                  <p className="b-txt-light">
                    {i18n.t(
                      'Include the Wi-Fi network manager in the menu bar.',
                    )}
                  </p>
                </div>
                <div>
                  <Toggle
                    checked={setting.isNetworkManager}
                    onToggle={() =>
                      update('isNetworkManager', !setting.isNetworkManager)
                    }
                  />
                </div>
              </div>
            </div>
            {LDFFpassportAllowOpenWifiNetworks && setting.isNetworkManager && (
              <div className="k-ss2-style-drawer-setting">
                <div className="k-ss2-style-drawer-setting-with-toggle">
                  <div>
                    <p className="b-txt">
                      {i18n.t('Allow connecting to unsecured Wi-Fi networks')}
                    </p>
                    <p className="b-txt-light b-mb1">
                      {i18n.t(
                        'Allows users to use the Wi-Fi network manager to connect to unsecured Wi-Fi networks.',
                      )}
                    </p>
                    <p className="b-txt-light">
                      {i18n.ut(
                        '<strong>Note: </strong> Captive Portal networks are not supported.',
                      )}
                    </p>
                  </div>
                  <div>
                    <Toggle
                      checked={setting.allowOpenWifiNetworks}
                      onToggle={() =>
                        update(
                          'allowOpenWifiNetworks',
                          !setting.allowOpenWifiNetworks,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3">{i18n.t('Banners')}</h3>
            <div ref={refs[2]} className="k-ss2-style-drawer-setting">
              <p className="b-txt b-mb-micro">{i18n.t('Lock message')}</p>
              <p className="b-txt-light b-mb1">
                {i18n.t('Choose to display or hide a lock message.')}
              </p>
              <Select
                options={lockOptions}
                value={lockOptions.find(
                  (opt) => opt.value === lockMessageOption,
                )}
                onChange={({ value }) => {
                  update('lockMessageOption', value);
                  if (value === 'custom' && !setting.customLockMessage) {
                    onInvalidate(2)(true);
                  } else {
                    onInvalidate(2)(false);
                  }
                }}
              />
              {lockMessageOption === 'custom' && (
                <TextInput
                  className="b-mt1 b-mb-tiny k-klogin-lock-banner-input"
                  style={{ height: '160px' }}
                  textArea
                  value={setting.customLockMessage}
                  onChange={(e) => update('customLockMessage', e.target.value)}
                  validator={requiredTextValidator(
                    [toggleValidation],
                    i18n.t('Selected option is a required field.'),
                  )}
                  onInvalidate={onInvalidate(2)}
                  maxLength={220}
                  placeholder={i18n.t('Add a custom lock message.')}
                />
              )}
            </div>
            <div ref={refs[3]} className="k-ss2-style-drawer-setting">
              <p className="b-txt b-mb-micro">{i18n.t('Policy banner')}</p>
              <p className="b-txt-light b-mb1">
                {i18n.t('Choose to display or hide a policy banner.')}
              </p>
              <Select
                options={policyOptions}
                value={policyOptions.find(
                  (opt) => opt.value === policyBannerOption,
                )}
                onChange={({ value }) => {
                  update('policyBannerOption', value);
                  if (
                    (value === 'plain' && !setting.policyBannerText) ||
                    (value === 'rtf' &&
                      !setting.policyBannerFile &&
                      !setting.policyBannerS3Key &&
                      !setting.policyBannerUrl)
                  ) {
                    onInvalidate(3)(true);
                  } else {
                    onInvalidate(3)(false);
                  }
                }}
              />
              {policyBannerOption === 'plain' && (
                <TextInput
                  className="b-mt1 b-mb-tiny"
                  style={{ height: '160px' }}
                  textArea
                  value={setting.policyBannerText}
                  onChange={(e) => update('policyBannerText', e.target.value)}
                  validator={requiredTextValidator(
                    [toggleValidation],
                    i18n.t('Selected option is a required field.'),
                  )}
                  onInvalidate={onInvalidate(3)}
                  maxLength={900}
                  placeholder={i18n.t('Add a plain-text policy banner.')}
                />
              )}
              {policyBannerOption === 'rtf' && (
                <div className="b-mt2">
                  {setting.policyBannerFile || setting.policyBannerUrl ? (
                    <RTFFileViewer
                      file={
                        setting.policyBannerFile ||
                        (setting.policyBannerUrl
                          ? {
                              ...setting.policyBannerFileMeta,
                              url: setting.policyBannerUrl,
                            }
                          : null)
                      }
                      onTrash={() => {
                        update('policyBannerFile', null);
                        update('policyBannerUrl', '');
                        update('policyBannerS3Key', '');
                        update('policyBannerFileMeta', {});
                        onInvalidate(3)(true);
                      }}
                      size="sm"
                    />
                  ) : (
                    <RTFFileSelect
                      allowedFileTypes={['rtf']}
                      onFileSelect={(files) => {
                        update('policyBannerFile', files[0]);
                        update('policyBannerUrl', '');
                        update('policyBannerFileMeta', {
                          name: files[0].name,
                          size: files[0].size,
                          uploadedAt: files[0].lastModified,
                        });
                        onInvalidate(3)(false);
                      }}
                      validators={[
                        (file) =>
                          requiredFileValidator()(
                            file || setting.policyBannerS3Key,
                          ),
                      ]}
                      toggleValidationOn={[toggleValidation]}
                    >
                      {({ icon }) => (
                        <div className="b-flex-col b-flex-vc">
                          <img
                            src={icon}
                            className="b-mb-tiny"
                            alt={i18n.t('upload')}
                            style={{
                              width: 'var(--b-gap2)',
                              height: 'var(--b-gap2)',
                            }}
                          />
                          <p className="b-txt">
                            {i18n.t('Drop RTF file or')}{' '}
                            <a
                              href=""
                              onClick={(e) => e.preventDefault()}
                              className="b-alink"
                            >
                              {i18n.t('upload')}
                            </a>
                          </p>
                        </div>
                      )}
                    </RTFFileSelect>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3 b-mb1">{i18n.t('Power controls')}</h3>
            <p className="b-txt-light b-mb2">
              {i18n.t(
                'Specify which power controls will be accessible from the login window.',
              )}
            </p>
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">{i18n.t('Shut Down button')}</p>
                </div>
                <div>
                  <Toggle
                    checked={setting.isShutdownButton}
                    onToggle={() =>
                      update('isShutdownButton', !setting.isShutdownButton)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">{i18n.t('Restart button')}</p>
                </div>
                <div>
                  <Toggle
                    checked={setting.isRestartButton}
                    onToggle={() =>
                      update('isRestartButton', !setting.isRestartButton)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">{i18n.t('Sleep button')}</p>
                </div>
                <div>
                  <Toggle
                    checked={setting.isSleepButton}
                    onToggle={() =>
                      update('isSleepButton', !setting.isSleepButton)
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="k-ss2-style-drawer-section">
            <h3 className="b-h3">{i18n.t('Username and Password')}</h3>
            {authSetting.idp &&
              authSetting.mode ===
                KandjiLoginService.authenticationMode.WEB_LOGIN && (
                <Banner kind="block" theme="info" className="b-mb2">
                  <span>
                    {i18n.t(
                      'Username and password reset will only be displayed at the Local Login window.',
                    )}
                  </span>
                </Banner>
              )}
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">{i18n.t('Customize username label')}</p>
                  <p className="b-txt-light">
                    {i18n.t(
                      'Add a custom label that will display in the username field.',
                    )}
                  </p>
                </div>
                <div ref={refs[4]}>
                  <Toggle
                    checked={isCustomUsername}
                    onToggle={() => {
                      if (isCustomUsername) {
                        update('customUsername', '');
                      }
                      update('isCustomUsername', !isCustomUsername);
                      if (!isCustomUsername && !setting.customUsername) {
                        onInvalidate(4)(true);
                      } else {
                        onInvalidate(4)(false);
                      }
                    }}
                  />
                </div>
              </div>
              {isCustomUsername && (
                <TextInput
                  className="b-mt2 b-mb-tiny"
                  value={setting.customUsername}
                  placeholder={i18n.t('Username')}
                  onChange={(e) => update('customUsername', e.target.value)}
                  maxLength={83}
                  validator={requiredTextValidator(
                    [toggleValidation],
                    i18n.t('Required field if toggle is on.'),
                  )}
                  onInvalidate={onInvalidate(4)}
                />
              )}
            </div>
            <div className="k-ss2-style-drawer-setting">
              <div className="k-ss2-style-drawer-setting-with-toggle">
                <div>
                  <p className="b-txt">
                    {i18n.t('Include password reset URL')}
                  </p>
                  <p className="b-txt-light">
                    {i18n.ut(
                      'Allow users to reset their passwords from the login window. <a href="https://support.kandji.io/support/solutions/articles/72000558707" rel="noopener noreferrer" target="_blank" class="b-alink" > Learn more... </a>',
                    )}
                  </p>
                </div>
                <div ref={refs[5]}>
                  <Toggle
                    checked={isIncludePasswordUrl}
                    onToggle={() => {
                      update('isIncludePasswordUrl', !isIncludePasswordUrl);
                      if (!isIncludePasswordUrl && !setting.passwordResetUrl) {
                        onInvalidate(5)(true);
                      } else {
                        onInvalidate(5)(false);
                      }
                    }}
                  />
                </div>
              </div>
              {isIncludePasswordUrl && (
                <TextInput
                  className="b-mt2 b-mb-tiny"
                  value={setting.passwordResetUrl}
                  placeholder="https://"
                  onChange={(e) => update('passwordResetUrl', e.target.value)}
                  maxLength={2048}
                  validator={(v) => [
                    {
                      message: i18n.t('Required'),
                      invalid: () => !v,
                      trigger: ['onBlur', toggleValidation],
                    },
                    urlValidator(v, {
                      options: { protocols: ['https'] },
                      trigger: ['onBlur', toggleValidation],
                    }),
                  ]}
                  onInvalidate={onInvalidate(5)}
                  textArea
                  hideMaxLength
                />
              )}
            </div>
          </div>
        </div>

        <div className="b-side-panel-layout__footer">
          <div className="b-flex-justify-end">
            <div className="b-grid-ctas">
              <Button kind="outline" onClick={onCancel}>
                {i18n.t('Cancel')}
              </Button>
              <Button
                onClick={() => {
                  const invalids = Object.values(
                    setting.invalidationsMap,
                  ).filter((d) => d.isInvalid);
                  if (!invalids.length) {
                    setIsVisible(false);
                  } else {
                    setToggleValidation((prev) => !prev);
                    const withRef = invalids.find((d) => d.ref);
                    withRef?.ref.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                      inline: 'nearest',
                    });
                  }
                }}
              >
                {i18n.t('Done')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
  );
};

export default Drawer;
