import { i18n } from 'i18n';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import featureFlags from 'src/config/feature-flags';

export const CompanyAccessLevelContent = () => (
  <>
    <p>
      <strong>{i18n.t('Admin')}:</strong>
      <span>
        {' '}
        {i18n.t(`Users with the ‘Admin’ Role have unrestricted visibility and control of
        Kandji, including Customer Settings.`)}
      </span>
    </p>
    <p>
      <strong>{i18n.t('Account Owner')}:</strong>
      <span>
        {' '}
        {i18n.t(
          'This is ‘Admin’ Role that cannot be deleted/modified by other Admins.',
        )}
      </span>
    </p>
    <p>
      <strong>{i18n.t('Standard')}:</strong>
      <span>
        {' '}
        {i18n.t(`Users with the ‘Standard’ Role do not have access to Customer Settings,
        but otherwise have read/write access to the platform.`)}
      </span>
    </p>
    <p>
      <strong>{i18n.t('Help Desk')}:</strong>
      <span>
        {' '}
        {i18n.t(`Users with the ‘Help Desk’ Role do not have access to customer settings,
        and have read only access to Blueprints and Library Items. Help Desk
        users can perform all device actions, including deleting a device.`)}
      </span>
    </p>
    <p>
      <strong>{i18n.t('Auditor')}:</strong>
      <span>
        {' '}
        {i18n.t(`Users with the ‘Auditor’ Role do not have access to Customer Settings,
        and have read-only access to the platform. Auditors can download
        packages/enroll Macs and create Notes.`)}
      </span>
    </p>
    {featureFlags.getFlag('secrets-auditor-role') && (
      <p>
        <strong>{i18n.t('Secrets Auditor')}:</strong>
        <span>
          {' '}
          {i18n.t(
            `Users with the 'Secrets Auditor' role have the same permissions as those with the 'Auditor' role, with the added ability to read device secrets, enabling more effective auditing and security monitoring.`,
          )}
        </span>
      </p>
    )}
  </>
);

export const CompanyAccessLevelHelper = (target) => (
  <UncontrolledTooltip
    placement="top"
    delay={{ show: 400, hide: 0 }}
    className="cover-all-helper"
    innerClassName="custom-helper role-helper"
    target={target}
  >
    <CompanyAccessLevelContent />
  </UncontrolledTooltip>
);
