import { useQueries } from '@tanstack/react-query';
import { api } from 'app/api/base';
import { URL_BLUEPRINTS } from 'app/api/urls';
import { AccountContext } from 'contexts/account';
import { EnvironmentContext } from 'contexts/environment';
import { integrationsBackendService } from 'features/integrations/data-service/integrations-backend-service';
import { newIntegrationsService } from 'features/integrations/data-service/new-integrations-service';
import { useFeature as useIntegrationsFeature } from 'features/integrations/hooks';
import { applyFiltersAndOrdering as filterIntegrations } from 'features/integrations/overview/calc';
import { newLibraryItemService } from 'features/library-items/data-service/library-item/new-library-item-service';
import { transformLibraryItems } from 'features/library-items/library/api/transformer';
import { getFilteredItems } from 'features/library-items/library/common';
import { libraryItemsOrder } from 'features/library-items/library/library';
import { prismService } from 'features/visibility/prism/data-service/prism-service';
import * as React from 'react';

const SEARCH_LIMIT = 100;

const getBlueprints = () => api(URL_BLUEPRINTS).get({});

const DEVICE_FAMILIES = ['Mac', 'iPhone', 'iPad', 'Apple TV'];

const deviceColumns = [
  { name: 'device__name', category: '' },
  { name: 'device_id', category: 'device_information' },
  { name: 'asset_tag', category: '' },
  { name: 'device__family', category: '' },
  { name: 'serial_number', category: '' },
  { name: 'device__user_name', category: '' },
  { name: 'device__user_email', category: '' },
  { name: 'model_id', category: '' },
];

export const useUniversalSearchQueries = ({
  search,
  deviceIds,
}: { search: string; deviceIds?: string[] }) => {
  const environment = React.useContext(EnvironmentContext);
  const account = React.useContext(AccountContext);
  const features = account?.currentCompany?.feature_configuration as any;
  const isThreatEnabled = features.edr?.enabled;
  const searchTerm = search.trim().toLowerCase();
  const { mapCategoryType } = useIntegrationsFeature();

  const queries = [
    {
      queryKey: [
        'universal-search-devices',
        searchTerm,
        (deviceIds || []).join(','),
      ],
      queryFn: () =>
        prismService.getViewData(
          { limit: SEARCH_LIMIT, offset: 0 },
          deviceColumns,
          deviceIds
            ? { 'device_information.device_id': { like: deviceIds } }
            : {},
          searchTerm,
        ),
      enabled: searchTerm.length > 0 || (deviceIds && deviceIds.length > 0),
    },
    {
      queryKey: ['universal-search-blueprints'],
      queryFn: () => getBlueprints(),
    },
    {
      queryKey: ['universal-search-library-items'],
      queryFn: () => newLibraryItemService.v2List(),
    },
    {
      queryKey: ['universal-search-integrations-main'],
      queryFn: () => newIntegrationsService.listIntegrations(''),
    },
    {
      queryKey: ['universal-search-integrations-backend'],
      queryFn: () =>
        integrationsBackendService.getIntegrations({ page: '0', size: '100' }),
    },
  ];

  const results = useQueries({ queries });

  const isPending =
    searchTerm.length > 0 && results.some((result) => result.isPending);

  const devices = results[0].data?.data ?? [];

  const blueprints = (results[1].data?.data ?? [])
    .filter(({ name }) => name.toLowerCase().includes(searchTerm))
    .slice(0, SEARCH_LIMIT);

  const libItemsOrder = React.useMemo(() => {
    return libraryItemsOrder(isThreatEnabled);
  }, [isThreatEnabled]);

  const transformedLibraryItems = transformLibraryItems(
    results[2].data?.data ?? [],
    { environment, account },
    true,
  );
  const filteredLibraryItems = getFilteredItems(
    transformedLibraryItems,
    { searchTerm, devices: DEVICE_FAMILIES, category: '' },
    libItemsOrder,
    (i) => i.defaultConfiguration.isHidden({ account, environment }),
  );
  const libraryItems = filteredLibraryItems
    .reduce((acc, { data }) => {
      return [...acc, ...data];
    }, [])
    .slice(0, SEARCH_LIMIT);

  const integrationsFetching = results[3].isFetching || results[4].isFetching;
  const integrationsMain = results[3].data?.data?.results ?? [];
  const integrationsBackend = results[4].data?.data?.items ?? [];
  const integrations = integrationsFetching
    ? []
    : filterIntegrations(
        [...integrationsMain, ...integrationsBackend].map((integration) => {
          const category = Object.entries(mapCategoryType).find(([, types]) =>
            (types as string[]).includes(integration?.type),
          )?.[0];
          return category ? { ...integration, category } : null;
        }),
        { search },
      ).slice(0, SEARCH_LIMIT);

  return {
    data: {
      devices,
      blueprints,
      libraryItems,
      integrations,
    },
    isPending,
  };
};

export const useUniversalSearchRecents = () => {
  const recentData = [
    {
      id: '8e3c01e6-537b-4548-b636-7f0b4f979179',
      type: 'devices',
    },
    {
      id: 'bff036a3-0a62-487a-883a-779f2e2e37cf',
      type: 'devices',
    },
    {
      id: '42d7faa3-a7da-4d0e-a2a7-3ef09bdcf4b9',
      type: 'blueprints',
    },
    {
      id: 'ab2fb593-fdfc-48cb-ac4c-915a68b0b9a0',
      type: 'libraryItems',
    },
    {
      id: 'ab2fb593-fdfc-48cb-ac4c-915a68b0b9a1',
      type: 'libraryItems',
    },
    {
      id: '3cb11fef-4e9c-419c-8add-89cdf30806bf',
      type: 'integrations',
    },
  ];
  const deviceIds = recentData
    .filter((r) => r.type === 'devices')
    .map((r) => r.id);
  const { isPending, data } = useUniversalSearchQueries({
    search: '',
    deviceIds,
  });
  if (isPending) {
    return { isPending, data: [] };
  }
  return {
    isPending,
    data: recentData
      .map((recent) => {
        const record = data[recent.type].find((searchResult) => {
          const idPath = getIdPath(recent);
          return searchResult[idPath] === recent.id;
        });
        return { record, type: recent.type };
      })
      .filter((r) => r.record != null),
  };
};

const getIdPath = (recent) => {
  if (recent.type === 'devices') {
    return 'device_information.device_id';
  }
  if (recent.type === 'integrations') {
    return 'uuid';
  }
  return 'id';
};
