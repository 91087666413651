import {
  Chip,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { Box } from '@kandji-inc/nectar-ui';
/* istanbul ignore file */
import React, { useEffect } from 'react';
import FeatureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';

import { devices } from 'features/library-items/data-service/library-item/devices';
import {
  i18nOsRequirements,
  osRequirements,
} from 'features/library-items/library/common';
import { Setting } from 'features/library-items/template';
import SetComputerName from '../dnd/SetComputerName';
import DeviceNameService from '../service/device-name-service';

const Computername = (props: any) => {
  const {
    update,
    updateHostName,
    isDisabled,
    isSubmitted,
    setting,
    hostNameSetting,
    devices: runOnDevices,
  } = props;

  const fieldsToValidate = ['variables'];
  const { onInvalidate, invalidations, refs } = useInputsValidators(
    fieldsToValidate,
    update,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, update);

  const hasDevice = (name) =>
    !!runOnDevices?.find(({ value }) => value === name);

  useEffect(() => {
    const hasVariables = setting.variables?.length > 0;
    onInvalidate('variables')(!hasVariables);
  }, [setting.variables]);

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('Device name')}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {FeatureFlags.getFlag('ios-apple-vision-pro')
            ? i18n.t(
                'On macOS Ventura, Sonoma, iOS, iPadOS, tvOS, and visionOS the device name can be found under Settings/About. The device name is how your device appears in Kandji and for services like AirDrop. ',
              )
            : i18n.t(
                'On macOS Ventura, Sonoma, iOS, iPadOS, and tvOS the device name can be found under Settings/About. The device name is how your device appears in Kandji and for services like AirDrop. ',
              )}
          <a
            href="https://support.kandji.io/support/solutions/articles/72000560507-setting-macos-and-ios-device-names"
            rel="noopener noreferrer"
            target="_blank"
            className="b-alink"
          >
            {i18n.t('Learn more')}
          </a>
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title>
            <p className="b-txt-bold">{i18n.t('Set a device name')}</p>
            <p className="b-txt">
              {i18n.t(
                'Drag variables into the Device name field to create the desired pattern. Additionally, you can add custom text or spaces between variables.',
              )}
            </p>
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'The device name is a short, friendly name that can contain capital letters, numbers, spaces and special characters. Device names cannot be more than 63 characters in length.',
              )}
            </p>
            <div className="b-flex-vc b-flex-g1">
              <Chip
                kind="tertiary-light"
                text={i18nOsRequirements(osRequirements.MAC_10_15)}
              />
              <Chip
                kind="tertiary-light"
                text={i18nOsRequirements(osRequirements.IOS_14_0)}
              />
              <Chip
                kind="tertiary-light"
                text={i18nOsRequirements(osRequirements.IPAD_14_0)}
              />
              <Chip
                kind="tertiary-light"
                text={i18nOsRequirements(osRequirements.TV_14_0)}
              />
              {FeatureFlags.getFlag('ios-apple-vision-pro') && (
                <Chip
                  kind="tertiary-light"
                  text={i18nOsRequirements(osRequirements.VISION_2_0)}
                />
              )}
            </div>
          </Setting.Helpers>
          <Box css={{ gridColumn: '1 / -1' }} ref={refs.variables}>
            <SetComputerName
              title="Device name"
              selectedVariables={setting.variables}
              setSelectedVariables={(vars) => {
                update('variables', vars);
                if (hostNameSetting.useDeviceNamePattern) {
                  updateHostName(
                    'variables',
                    DeviceNameService.convertDeviceNameToHostName(
                      vars(setting.variables),
                    ),
                  );
                }
              }}
              onDevices={[
                { device: devices.MAC, disabled: !hasDevice(devices.MAC) },
                {
                  device: devices.IPHONE,
                  disabled: !hasDevice(devices.IPHONE),
                },
                { device: devices.IPAD, disabled: !hasDevice(devices.IPAD) },
                { device: devices.TV, disabled: !hasDevice(devices.TV) },
              ]}
              hasError={invalidations[0] && isSubmitted}
              isDisabled={isDisabled}
            />
          </Box>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Computername;
