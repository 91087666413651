import { Select } from '@kandji-inc/nectar-ui';
import React from 'react';

import { Setting } from 'features/library-items/template';
import { i18n } from 'src/i18n';
import type { GeneralProps } from '../../avert.types';
import AvertService from '../../service/avert.service';

const General = (props: GeneralProps) => {
  const { isDisabled, update, settings } = props;

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t(`File detections`)}</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          {i18n.t(
            `Configure the response postures for malware and Potentially Unwanted Programs (PUP) detection events.`,
          )}
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Helpers className="b-txt-light">
            {i18n.ut(
              `Detect mode scans and reports known malicious items. Protect mode scans, reports, and automatically quarantines known malicious items.<br><br>Note: When deploying the Avert Library Item, file detections are required and cannot be turned off.`,
            )}
          </Setting.Helpers>
          <Setting.Controls>
            <div className="b-mb3">
              <Select
                label={i18n.t('Malware response posture')}
                options={AvertService.postureOptions()}
                value={settings.malware_posture}
                onChange={(v) => update('malware_posture', v)}
                disabled={isDisabled}
                testId="malware_posture"
              />
            </div>
            <Select
              label={i18n.t('PUP response posture')}
              options={AvertService.postureOptions()}
              value={settings.pup_posture}
              onChange={(v) => update('pup_posture', v)}
              disabled={isDisabled}
              testId="pup_posture"
            />
          </Setting.Controls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default General;
