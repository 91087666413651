/* istanbul ignore file */
import type { Node } from 'reactflow';
import featureFlags from 'src/config/feature-flags';
import type { DraggableLibraryItem, LibraryItem } from './blueprint-flow.types';

import { apiTypes } from '../library-items/library/common';
import { NODE_TYPES, ONLY_ALL_DEVICES_LIBRARY_ITEMS } from './constants';

const getIsSCLIConflict = ({
  droppedItem,
  existingItem,
  getLibraryItem,
}: {
  droppedItem: DraggableLibraryItem;
  existingItem: DraggableLibraryItem;
  getLibraryItem: (id: string) => LibraryItem;
}) => {
  const newItemId = droppedItem.data.id;
  const newItemData = getLibraryItem(newItemId);

  if (!newItemData) {
    return false;
  }

  const newItemSingleBlueprintAllowed =
    newItemData?.defaultConfiguration?.singleBlueprintAllowed ||
    newItemData?.defaultConfiguration?.singleAssignmentMappingAllowed;

  const existingItemId = existingItem.data.id;
  const existingItemData = getLibraryItem(existingItemId);

  if (!existingItemData) {
    return false;
  }

  return (
    newItemSingleBlueprintAllowed &&
    newItemData.type === existingItemData.type &&
    newItemData.identifier === existingItemData.identifier &&
    !(
      existingItemData.type === apiTypes.AUTO_APP &&
      newItemData.managedLibraryItemId !== existingItemData.managedLibraryItemId
    )
  );
};

export const canDropIntoAssignmentNode = ({
  droppedItem,
  droppedNode,
  getLibraryItem,
}: {
  droppedItem: DraggableLibraryItem;
  droppedNode: Node;
  getLibraryItem: (id: string) => LibraryItem;
}): {
  isValid: boolean;
  isSCLIConflict: boolean;
  isAllDevicesConflict: boolean;
  conflictItem?: LibraryItem;
} => {
  const temporaryAllDevicesLibraryItems = featureFlags.getFlag(
    'dc_03202024_am-all-devices-library-items',
  )?.types;
  const droppedItemId = droppedItem.data.id;
  const droppedLibraryItem = getLibraryItem(droppedItemId);
  const existingItems = droppedNode.data?.items || [];

  // Prevent dropping certain LIs into anything other than the Start node
  const isAllDevicesConflict =
    droppedNode.type === NODE_TYPES.assignment &&
    (ONLY_ALL_DEVICES_LIBRARY_ITEMS.includes(droppedLibraryItem.type) ||
      temporaryAllDevicesLibraryItems.includes(droppedLibraryItem.type));

  const isExistingLibraryItem = existingItems.some(
    (existingItem: DraggableLibraryItem) => {
      const existingItemId = existingItem.data.id;
      return existingItemId === droppedItemId;
    },
  );

  const existingItemIdCausingSCLIConflict = existingItems.find(
    (existingItem: DraggableLibraryItem) =>
      getIsSCLIConflict({ droppedItem, existingItem, getLibraryItem }),
  )?.data?.id;

  const isSCLIConflict =
    !isExistingLibraryItem && Boolean(existingItemIdCausingSCLIConflict);

  const isValid =
    !isExistingLibraryItem && !isSCLIConflict && !isAllDevicesConflict;

  return {
    isValid,
    isSCLIConflict,
    isAllDevicesConflict,
    conflictItem: existingItemIdCausingSCLIConflict
      ? getLibraryItem(existingItemIdCausingSCLIConflict)
      : null,
  };
};
