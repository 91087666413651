import {
  Button,
  DialogPrimitives as Dialog,
  Flex,
  Icon,
  Separator,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import deviceImagesMap from 'components/common/image-device/map';
import { getItemConfig as getLibraryItemConfig } from 'features/blueprint-flow/helpers';
import { paths } from 'features/blueprints/common';
import { default as integrationStrings } from 'features/integrations/generic-cards-view/cards-config';
import { getDeviceIconByFamily } from 'features/visibility/prism/utils/column-utils';
import { i18n } from 'i18n';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { links } from 'src/app/common/constants';
import { useDebouncedState } from 'src/hooks/useDebouncedState';
import {
  useUniversalSearchQueries,
  useUniversalSearchRecents,
} from '../hooks/universal-search-queries';
import { NoResultsFoundIcon } from './NoResultsFoundIcon';

const COLLAPSED_TOTAL = 12;

const getDeviceIcon = (modelId: string) =>
  deviceImagesMap[modelId] ?? getDeviceIconByFamily(modelId);

const NavIcon = styled(Icon, {
  borderRadius: '2px',
  color: '$neutral0',
  backgroundColor: '$neutral50',
});

const NavText = styled(Text, {
  color: '$blue50',
  visibility: 'hidden',
  fontWeight: '$medium',
  fontSize: '$2',
});

const StyledSearchListItem = styled(Flex, {
  padding: '6px $5',
  gap: '$1',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '$neutral0',
  '& [data-role="nav-icon"]': {
    color: '$neutral0',
  },
  '&[data-selected=true]': {
    backgroundColor: '$button_subtle_surface_hover',
    [`& ${NavIcon}`]: {
      color: '$blue50',
      backgroundColor: 'transparent',
    },
    [`& ${NavText}`]: {
      visibility: 'visible',
    },
  },
});

const ItemSummary = styled(Flex, {
  flexDirection: 'column',
  maxWidth: 'calc(100% - 210px)',
  overflow: 'hidden',
});

const LoadingIcon = styled('div', {
  height: 24,
  width: 24,
  backgroundColor: '$neutral05',
  borderRadius: '$rounded',
});

const LoadingBar = styled('div', {
  height: 24,
  backgroundColor: '$neutral05',
  borderRadius: '$rounded',
  variants: {
    size: {
      sm: {
        width: '30%',
      },
      md: {
        width: '50%',
      },
    },
  },
});

const ResourceSection = ({ title, children }) => {
  return (
    <Flex flow="column">
      <Flex css={{ padding: '6px $5', alignItems: 'center' }}>
        <Text size="1" weight="medium" variant="description">
          {title}
        </Text>
      </Flex>
      {children}
    </Flex>
  );
};

const DeviceListItem = ({ device, isHighlighted, onSelect, onHighlight }) => {
  const {
    asset_tag: assetTag,
    device__family: deviceFamily,
    device__name: deviceName,
    model_id: modelId,
    serial_number: serialNumber,
    device__user_name: userName,
    device__user_email: userEmail,
  } = device;
  const icon = getDeviceIcon(modelId);
  return (
    <StyledSearchListItem
      data-testid="device-list-item"
      data-selected={isHighlighted}
      css={{ paddingLeft: '20px' }}
      onClick={(e) => onSelect(device, e.metaKey)}
      onMouseMove={() => onHighlight(device)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <img
          data-testid="device_icon_image"
          height="32"
          width="32"
          src={icon}
          alt={deviceFamily}
        />
      </Flex>
      <ItemSummary>
        <Text css={{ fontWeight: '$medium' }}>{deviceName}</Text>
        <Text css={{ fontSize: '$1' }}>
          {modelId} - {serialNumber}
          {assetTag && ' - '}
          {assetTag}
        </Text>
        <Flex
          alignItems="center"
          css={{
            gap: '2px',
            maxHeight: '16px',
            overflowX: 'hidden',
            fontSize: '$1',
          }}
        >
          {userName && (
            <>
              <Flex css={{ flex: 'none' }}>
                <Icon size="xs" name="user" />
              </Flex>
              <Text css={{ flex: 'none' }}>
                {userName} {userEmail && ' - '}
              </Text>
            </>
          )}
          {userEmail && (
            <>
              <Flex css={{ flex: 'none' }}>
                <Icon size="xs" name="envelope" />
              </Flex>
              <Text css={{ flex: 'none' }}>{userEmail}</Text>
            </>
          )}
          {!userName && !userEmail && (
            <Text css={{ fontStyle: 'italic' }}>User not assigned</Text>
          )}
        </Flex>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Device record')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

const BlueprintListItem = ({
  blueprint,
  isHighlighted,
  onSelect,
  onHighlight,
}) => {
  return (
    <StyledSearchListItem
      data-testid="blueprint-list-item"
      data-selected={isHighlighted}
      onClick={(e) => onSelect(blueprint, e.metaKey)}
      onMouseMove={() => onHighlight(blueprint)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <Icon size={20} name="kandji-blueprint" />
      </Flex>
      <ItemSummary>
        <Text>{blueprint.name}</Text>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Blueprint')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

const LibraryItemListItem = ({
  libraryItem,
  isHighlighted,
  onSelect,
  onHighlight,
}) => {
  const itemConfig = getLibraryItemConfig(libraryItem);
  const [icon, setIcon] = useState(libraryItem.icon);

  return (
    <StyledSearchListItem
      data-testid="library-list-item"
      data-selected={isHighlighted}
      onClick={(e) => onSelect(libraryItem, e.metaKey)}
      onMouseMove={() => onHighlight(libraryItem)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <img
          height="20"
          width="20"
          data-testid="device_icon_image"
          src={icon || itemConfig.icon}
          onError={() => setIcon(itemConfig.icon)}
          alt={libraryItem.name}
        />
      </Flex>
      <ItemSummary>
        <Text css={{ fontWeight: '$medium' }}>{libraryItem.name}</Text>
        <Text variant="secondary" css={{ fontSize: '$1' }}>
          {itemConfig.getName() || itemConfig.name}
          {libraryItem.instance_name ? ` - ${libraryItem.instance_name}` : ''}
        </Text>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Library Item')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

// istanbul ignore next
const IntegrationListItem = ({
  integration,
  isHighlighted,
  onSelect,
  onHighlight,
}) => {
  const { title, img } = integrationStrings[integration.type];
  const name =
    integration.category === 'Directory integrations'
      ? integration.name
      : title();
  const subtitle =
    integration.category === 'Directory integrations' ? title() : '';
  const separator = subtitle && integration.category ? ' - ' : '';
  return (
    <StyledSearchListItem
      data-testid="integration-list-item"
      data-selected={isHighlighted}
      onClick={(e) => onSelect(integration, e.metaKey)}
      onMouseMove={() => onHighlight(integration)}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        css={{ flex: 'none' }}
        p1
      >
        <img
          height="20"
          width="20"
          data-testid="device_icon_image"
          src={img}
          alt={integration.name}
        />
      </Flex>
      <ItemSummary>
        <Text css={{ fontWeight: '$medium' }}>{name}</Text>
        <Text variant="secondary" css={{ fontSize: '$1' }}>
          {`${subtitle}${separator}${integration.category ?? ''}`}
        </Text>
      </ItemSummary>
      <Flex flex="1" gap="xs" alignItems="center" justifyContent="end">
        <NavText>{i18n.t('Go to Integration')}</NavText>
        <NavIcon size="sm" name="fa-arrow-right-to-line-control" />
      </Flex>
    </StyledSearchListItem>
  );
};

const EmptyScreen = ({ search }) => {
  const message = search
    ? i18n.t('No results found')
    : i18n.t('Search for a device, Library items, Blueprints or integrations.');
  return (
    <Flex
      css={{
        padding: '$5',
        gap: '$3',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      data-testid="empty-screen"
    >
      <Text variant="secondary" size="1" weight="medium">
        {message}
      </Text>
      <NoResultsFoundIcon />
    </Flex>
  );
};

const RecentsSection = ({
  recents,
  onSelect,
  highlightedIndex,
  onHighlight,
}) => {
  return (
    <ResourceSection title={i18n.t('Recents')}>
      {recents.map((recent, index) => {
        if (recent.type === 'devices') {
          return (
            <DeviceListItem
              key={recent.record['device_information.device_id']}
              device={recent.record}
              onSelect={(record, newTab) => onSelect(record, newTab, 'devices')}
              isHighlighted={index === highlightedIndex}
              onHighlight={onHighlight}
            />
          );
        }
        if (recent.type === 'blueprints') {
          return (
            <BlueprintListItem
              key={recent.record.id}
              blueprint={recent.record}
              onSelect={(record, newTab) =>
                onSelect(record, newTab, 'blueprints')
              }
              isHighlighted={index === highlightedIndex}
              onHighlight={onHighlight}
            />
          );
        }
        if (recent.type === 'libraryItems') {
          return (
            <LibraryItemListItem
              key={recent.record.id}
              libraryItem={recent.record}
              onSelect={(record, newTab) =>
                onSelect(record, newTab, 'libraryItems')
              }
              isHighlighted={index === highlightedIndex}
              onHighlight={onHighlight}
            />
          );
        }
        if (recent.type === 'integrations') {
          return (
            <IntegrationListItem
              key={recent.record.uuid}
              integration={recent.record}
              onSelect={(record, newTab) =>
                onSelect(record, newTab, 'integrations')
              }
              isHighlighted={index === highlightedIndex}
              onHighlight={onHighlight}
            />
          );
        }
      })}
    </ResourceSection>
  );
};

const LoadingItem = ({ size }: { size: 'sm' | 'md' }) => (
  <Flex gap="xs" css={{ padding: '6px $5', alignItems: 'center' }}>
    <LoadingIcon />
    <LoadingBar size={size} />
  </Flex>
);

const LoadingScreen = ({ length }) => (
  <Flex flow="column" gap="xs" data-testid="loading-screen">
    {Array.from({ length }).map((_, idx) => (
      <LoadingItem size={idx % 2 === 0 ? 'sm' : 'md'} />
    ))}
  </Flex>
);

export const UniversalSearchDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const history = useHistory();
  const [debouncedSearch, setSearch, search] = useDebouncedState<string>('');
  const [expandedSections, setExpandedSections] = useState({
    devices: false,
    blueprints: false,
    libraryItems: false,
    integrations: false,
  });
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const { data, isPending: searchPending } = useUniversalSearchQueries({
    search,
  });
  const { data: recents, isPending: recentsPending } =
    useUniversalSearchRecents();
  const isPending = (search.trim() && searchPending) || recentsPending;
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      setSearch('');
    }
  }, [isOpen, setSearch]);

  useEffect(() => {
    setExpandedSections({
      devices: false,
      blueprints: false,
      libraryItems: false,
      integrations: false,
    });
    setHighlightedIndex(-1);
  }, [debouncedSearch]);

  // istanbul ignore next
  useEffect(() => {
    if (scrollRef.current) {
      const { top: contentTop, bottom: contentBottom } =
        scrollRef.current.getBoundingClientRect();
      const selectedEl = scrollRef.current.querySelector(
        '[data-selected=true]',
      );
      if (!selectedEl) {
        return;
      }
      const { top: selectedTop, bottom: selectedBottom } =
        selectedEl.getBoundingClientRect();
      if (selectedBottom > contentBottom) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollTop + selectedBottom - contentBottom,
        });
      } else if (selectedTop < contentTop) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollTop + selectedTop - contentTop,
        });
      }
    }
  }, [highlightedIndex]);

  const onDeviceClick = useCallback(
    (device, newTab) => {
      const deviceId = device['device_information.device_id'];
      const path = `${links.devices}/${deviceId}`;
      if (newTab) {
        window.open(path, '_blank');
        return;
      }
      onClose();
      history.push(path);
    },
    [history, onClose],
  );

  const onBlueprintClick = useCallback(
    (blueprint, newTab) => {
      const path =
        blueprint.type === 'flow'
          ? paths.flowBlueprint(blueprint.id)
          : paths.blueprint(blueprint.id);
      if (newTab) {
        window.open(path, '_blank');
        return;
      }
      onClose();
      history.push(path);
    },
    [history, onClose],
  );

  const onLibraryItemClick = useCallback(
    (libraryItem, newTab) => {
      const path = libraryItem.getUrl({ id: libraryItem.id });
      if (newTab) {
        window.open(path, '_blank');
        return;
      }
      onClose();
      history.push(path);
    },
    [history, onClose],
  );

  const onIntegrationClick = useCallback(
    (integration, newTab) => {
      const path = `/integrations/${integration.type}`;
      if (newTab) {
        window.open(path, '_blank');
        return;
      }
      onClose();
      history.push(path);
    },
    [history, onClose],
  );

  // istanbul ignore next
  const onRecentClick = (record, newTab, recordType) => {
    if (recordType === 'devices') {
      onDeviceClick(record, newTab);
    } else if (recordType === 'blueprints') {
      onBlueprintClick(record, newTab);
    } else if (recordType === 'libraryItems') {
      onLibraryItemClick(record, newTab);
    } else if (recordType === 'integrations') {
      onIntegrationClick(record, newTab);
    }
  };

  const sectionData = useMemo(() => {
    const sections = {
      devices: { data: [], next: 'blueprints' },
      blueprints: { data: [], next: 'libraryItems' },
      libraryItems: { data: [], next: 'integrations' },
      integrations: { data: [], next: 'devices' },
    };
    if (search.trim() === '' || searchPending) {
      return sections;
    }
    let totalCount =
      data.devices.length +
      data.blueprints.length +
      data.libraryItems.length +
      data.integrations.length;
    let selectedCount = 0;
    let currentSection = 'devices';
    while (selectedCount < COLLAPSED_TOTAL && totalCount > 0) {
      const itemIdx = sections[currentSection].data.length;
      const item = data[currentSection][itemIdx];
      if (item != null) {
        sections[currentSection].data.push(item);
        selectedCount++;
        totalCount--;
      }
      currentSection = sections[currentSection].next;
    }
    Object.keys(expandedSections).forEach((section) => {
      if (expandedSections[section]) {
        sections[section].data = [...data[section]];
      }
    });
    return sections;
  }, [data, expandedSections, search, searchPending]);

  const geHighlightedRecord = useCallback(() => {
    if (search.trim() === '') {
      return recents[highlightedIndex];
    }
    let recordIndex = 0;
    let highlightedRecord = null;
    let highlightedRecordType = null;
    Object.keys(sectionData).forEach((section) => {
      sectionData[section].data.forEach((record) => {
        if (recordIndex === highlightedIndex) {
          highlightedRecord = record;
          highlightedRecordType = section;
        }
        recordIndex++;
      });
    });
    return { record: highlightedRecord, type: highlightedRecordType };
  }, [highlightedIndex, recents, search, sectionData]);

  const setHighlightedRecordIndex = useCallback(
    (record) => {
      const allRecords =
        search.trim() === ''
          ? recents.map(({ record }) => record)
          : [
              ...sectionData.devices.data,
              ...sectionData.blueprints.data,
              ...sectionData.libraryItems.data,
              ...sectionData.integrations.data,
            ];
      setHighlightedIndex(allRecords.indexOf(record));
    },
    [recents, sectionData, search],
  );

  const Expander = useCallback(
    ({ section }) => {
      const remainingCount =
        data[section].length - sectionData[section].data.length;
      return (
        search.trim() &&
        (remainingCount > 0 || expandedSections[section]) && (
          <Flex
            css={{ padding: '6px $5', alignItems: 'center' }}
            data-testid="section-expander"
          >
            <Button
              variant="link"
              onClick={() =>
                setExpandedSections((prev) => ({
                  ...prev,
                  [section]: !prev[section],
                }))
              }
            >
              {expandedSections[section]
                ? i18n.t('See less')
                : i18n.t('See more ({count})', {
                    count: remainingCount,
                  })}
            </Button>
          </Flex>
        )
      );
    },
    [expandedSections, search, data, sectionData],
  );

  const totalResults =
    search.trim() === ''
      ? recents.length
      : sectionData.devices.data.length +
        sectionData.blueprints.data.length +
        sectionData.libraryItems.data.length +
        sectionData.integrations.data.length;

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Content
        hideClose
        animateOpen={false}
        data-testid="universal-search-dialog"
        onClose={onClose}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            setHighlightedIndex((prev) =>
              prev === totalResults - 1 ? 0 : prev + 1,
            );
          } else if (e.key === 'ArrowUp') {
            setHighlightedIndex((prev) =>
              prev === 0 || prev === -1 ? totalResults - 1 : prev - 1,
            );
          } else if (e.key === 'Enter') {
            const { record, type } = geHighlightedRecord();
            if (type === 'devices') {
              onDeviceClick(record, e.metaKey);
            } else if (type === 'blueprints') {
              onBlueprintClick(record, e.metaKey);
            } else if (type === 'libraryItems') {
              onLibraryItemClick(record, e.metaKey);
            } else if (type === 'integrations') {
              onIntegrationClick(record, e.metaKey);
            }
          }
        }}
        css={{
          maxWidth: '800px',
          width: '70%',
          minWidth: '400px',
          height: 'auto',
          maxHeight: 'calc(100vh - 180px)',
          padding: '$4 0',
          position: 'fixed',
          top: '90px',
          transform: 'translate(-50%, 0)',
          boxShadow: '$elevation3',
          border: '1px solid $neutral20',
          borderRadius: '$rounded-lg',
        }}
      >
        <TextField
          showClearButton={debouncedSearch.length > 0}
          onClear={() => setSearch('')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={i18n.t('Search for anything')}
          css={{ paddingLeft: '$5', paddingRight: '$5' }}
          componentCss={{
            input: { border: 'none', boxShadow: 'none !important' },
          }}
        />
        <Separator />
        {search.trim() === '' && recents.length > 0 && (
          <RecentsSection
            recents={recents}
            onSelect={onRecentClick}
            highlightedIndex={highlightedIndex}
            onHighlight={setHighlightedRecordIndex}
          />
        )}
        {search.trim() === '' && recents.length === 0 && (
          <EmptyScreen search={debouncedSearch} />
        )}
        {isPending && <LoadingScreen length={6} />}
        {search.trim() && !searchPending && totalResults === 0 && (
          <EmptyScreen search={debouncedSearch} />
        )}
        {search.trim() && !searchPending && totalResults !== 0 && (
          <Flex
            ref={scrollRef}
            gap="md"
            flow="column"
            css={{ overflowY: 'scroll' }}
          >
            <Flex flow="column">
              {sectionData.devices.data.length > 0 && (
                <ResourceSection title={i18n.t('Devices')}>
                  {sectionData.devices.data.map((device) => (
                    <DeviceListItem
                      key={device['device_information.device_id']}
                      device={device}
                      onSelect={onDeviceClick}
                      isHighlighted={geHighlightedRecord().record === device}
                      onHighlight={setHighlightedRecordIndex}
                    />
                  ))}
                </ResourceSection>
              )}
              <Expander section="devices" />
              {sectionData.blueprints.data.length > 0 && (
                <ResourceSection title={i18n.t('Blueprints')}>
                  {sectionData.blueprints.data.map((blueprint) => (
                    <BlueprintListItem
                      key={blueprint.id}
                      blueprint={blueprint}
                      onSelect={onBlueprintClick}
                      isHighlighted={geHighlightedRecord().record === blueprint}
                      onHighlight={setHighlightedRecordIndex}
                    />
                  ))}
                </ResourceSection>
              )}
              <Expander section="blueprints" />
              {sectionData.libraryItems.data.length > 0 && (
                <ResourceSection title={i18n.t('Library Items')}>
                  {sectionData.libraryItems.data.map((libraryItem) => (
                    <LibraryItemListItem
                      key={libraryItem.id}
                      libraryItem={libraryItem}
                      onSelect={onLibraryItemClick}
                      isHighlighted={
                        geHighlightedRecord().record === libraryItem
                      }
                      onHighlight={setHighlightedRecordIndex}
                    />
                  ))}
                </ResourceSection>
              )}
              <Expander section="libraryItems" />
              {sectionData.integrations.data.length > 0 && (
                <ResourceSection title={i18n.t('Integrations')}>
                  {sectionData.integrations.data.map((integration) => (
                    <IntegrationListItem
                      key={integration.uuid}
                      integration={integration}
                      onSelect={onIntegrationClick}
                      isHighlighted={
                        geHighlightedRecord().record === integration
                      }
                      onHighlight={setHighlightedRecordIndex}
                    />
                  ))}
                </ResourceSection>
              )}
              <Expander section="integrations" />
            </Flex>
          </Flex>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};
